import logo from "./assets/images/Logo-mobilaway.png";
import smallLogo from "./assets/images/smallLogo.svg";
import iosLogo from "./assets/images/ios.svg";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="content">
        <a
          className="App-link"
          href="https://apps.apple.com/us/app/mobilawy/id1624508976"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={iosLogo} className="App-link-logo" alt="ios" />
        </a>
        <a
          className="App-link App-link-android"
          href="https://play.google.com/store/apps/details?id=com.mobilawy.consumer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            className="App-link-logo"
          />
        </a>
      </div>
      <div>
        <img src={smallLogo} className="App-small-logo" alt="logo" />
        <p style={{ fontSize: "14px", padding: "10px" }}>
          © Copyright 2024 Exxon Mobil Corporation. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default App;
